<template>
  <v-app>
    <Navbar :key="$store.state.isUserLoggedIn"></Navbar>
    <v-main class="mx-2 mt-4 mb-4">
      <router-view></router-view>
    </v-main>
    <!-- <v-footer
      absolute
      class="font-weight-medium"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        {{ new Date().getFullYear() }} <strong>秋邀</strong>
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import AuthenticationService from '@/services/AuthenticationService'
import consola from 'consola'
import { RoleCodes } from './enums'

const config = require('./config/config')

export default {
  name: 'App',
  components: {
    Navbar
  },
  data () {
    return {
      //
    }
  },
  async mounted () {
    let verified = true
    // check version: 此处在程序更新store结构时会设置新的版本号，并要求全部用户重新登录(已获得新的store数据结构)
    consola.info('[App.vue] checking version ...')
    if (this.$store.state.version !== config.version) {
      verified = false
      consola.warn(`[App.vue] version mis-match client side version: ${this.$store.state.version}; required version: ${config.version}.`)
    } else {
      consola.success(`[App.vue] version match: ${this.$store.state.version}`)
    }

    // check login state
    consola.info('[App.vue] checking login status ... ')
    if (!this.$store.state.isUserLoggedIn) {
      verified = false
    } else { // user is logged in
      consola.success('[App.vue] User logged in')
      console.log('身份代码为:')
      console.log(this.$store.state.roleCodes)
      console.log(RoleCodes)
    }

    // verify token
    consola.info(`[App.vue] verify token ...`)
    if (this.$store.state.token) {
      let res = await AuthenticationService.verifyToken(this.$store.state.token)
      if (!res.data.result) {
        consola.warn(`[App.vue] token is not valid.`)
        verified = false
      }
    }

    // if any of above not pass, clear the storage and direct to login page.
    if (!verified) {
      // 跳转至登录页面
      if (this.$store.state.route.name !== 'login' &&
        this.$store.state.route.name !== 'password-reset' // 在该页面时不跳转，不能使用在对应页面中使用 code split / lazy loading，见 @/router.js
      ) {
        this.$router.push({ name: 'login' })
      }
      consola.info(`[App.vue] one of the above not pass, clear storage ...`)
      // 清理 store
      this.$store.dispatch('setToken', null)
      this.$store.dispatch('setAccount', null)
      this.$store.dispatch('setVersion', null)
      this.$store.dispatch('setRoleCodes', null)
      this.$store.dispatch('setQueryingState', null)
      this.$store.dispatch('setUser', null)
    } else { // 检验通过: 跳转到相应页面
      const roleCodes = this.$store.state.roleCodes
      if (this.$store.state.route.name === 'login') {
        if (roleCodes.includes(RoleCodes.ADMIN)) { // 超级管理员: 主要查看利润情况
          console.log(`[App.vue] re-direct to lesson-template`)
          this.$router.push({ name: 'lesson-templates' })
          this.$router.push({
            name: '',
            query: {
              adminId: this.$store.state.account.admin.id
            }
          })
        } else if (roleCodes.includes(RoleCodes.SYSTEM)) { // 系统：主要查看利润
          console.log(`[App.vue] re-direct to lesson-template`)
          this.$router.push({ name: '' })
        } else if (roleCodes.includes(RoleCodes.MANAGER)) { // 片区经理，主要查看机构排课情况
          this.$router.push({
            name: '',
            query: {
              managerId: this.$store.state.account.manager.id
            }
          })
        } else if (roleCodes.includes(RoleCodes.INVITER)) {
        } else if (roleCodes.includes(RoleCodes.ORGANIZATION)) {
        } else if (roleCodes.includes(RoleCodes.TUTOR)) {
        } else if (roleCodes.includes(RoleCodes.TRAINER)) {
        } else if (roleCodes.includes(RoleCodes.HOST)) {
        } else if (roleCodes.includes(RoleCodes.STUDENT)) {
        }
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 微软雅黑, 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
</style>
