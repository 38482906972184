import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({ // the following are global states need to track throughout the app
  strict: true, // no modification on the following states is allowed
  plugins: [
    createPersistedState() // persistent state
  ],
  state: {
    token: null,
    isUserLoggedIn: false,
    queryingState: false,
    account: null,
    roleCodes: [],
    version: '',
    user: {}
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      if (token) {
        state.isUserLoggedIn = true
      } else {
        state.isUserLoggedIn = false
      }
    },
    setAccount (state, account) {
      state.account = account
    },
    setRoleCodes (state, roleCodes) {
      state.roleCodes = roleCodes
    },
    setQueryingState (state, queryingState) {
      state.queryingState = queryingState
    },
    setVersion (state, version) {
      state.version = version
    },
    setUser (state, user) {
      state.user = user
    }
  },
  actions: { // the definitions below will call corresponding mutation methods defined above
    setToken ({ commit }, token) {
      commit('setToken', token)
    },
    setQueryingState ({ commit }, state) {
      commit('setQueryingState', state)
    },
    setAccount ({ commit }, account) {
      commit('setAccount', account)
    },
    setRoleCodes ({ commit }, roleCodes) {
      commit('setRoleCodes', roleCodes)
    },
    setVersion ({ commit }, version) {
      commit('setVersion', version)
    },
    setUser ({ commit }, user) {
      commit('setUser', user)
    }
  }
})
