/**
 * 该文件为定义枚举常量的模块，需要在 enums/index.js 中进行集中导出
 */

const ProgramStatusLookup = Object.freeze({
  CLOSED: 0,
  // HIDDEN: 0,
  RECYCLED: 1,
  DRAFT: 2,
  RESERVED_3: 3,
  RESERVED_4: 4,
  RESERVED_5: 5,
  RESERVED_6: 6,
  RESERVED_7: 7,
  RESERVED_8: 8,
  PUBLISHED: 9
})

const ProgramCommonPropertyStatusLookup = Object.freeze({
  DRAFT: 3,
  PENDING: 4,
  APPROVED: 5
})

const ClassStatusLookup = Object.freeze({
  INACTIVE: 0,
  ACTIVE: 1,
  SUSPENDED: 2,
  ENROLLINGHOST: 3
})

const MediaCategoryLookup = Object.freeze({
  TEXT: 1,
  IMAGE: 2,
  VIDEO: 3
})

const VenueStatusLookup = Object.freeze({
  INACTIVE: 0,
  WAITLISTED: 1,
  ACTIVE: 2
})

export {
  ProgramStatusLookup,
  ProgramCommonPropertyStatusLookup,
  ClassStatusLookup,
  MediaCategoryLookup,
  VenueStatusLookup
}
