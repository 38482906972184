import Api from '@/services/Api' // axois object created in api.js
const prefix = '/user/'

export default { // export its register method
  registerUser (credentials) {
    return Api().post(prefix + 'register-user', {
      mobile: credentials.mobile,
      user_name: credentials.user_name,
      password: credentials.password
    }) // axios object's post method will be used to pass credentials to server
  },
  registerTutor (tutorInfo) {
    return Api().post(prefix + 'register-tutor', {
      nickname: tutorInfo.nickname,
      sex: tutorInfo.sex,
      user_id: tutorInfo.userId,
      organization_id: tutorInfo.organizationId
    }) // axios object's post method will be used to pass credentials to server
  },
  registerStudent (studentInfo) {
    return Api().post(prefix + 'register-student', {
      nickname: studentInfo.nickname,
      sex: studentInfo.sex,
      birth_year: studentInfo.birthYear,
      birth_month: studentInfo.birthMonth,
      user_id: studentInfo.userId
    }) // axios object's post method will be used to pass credentials to server
  },
  login (credentials) {
    return Api().post(prefix + 'login', {
      account: credentials.mobile,
      secret: credentials.password,
      role: credentials.roleCode,
      type: 2 // MOBILE_PW see enum.js on server side.
    }) // axios object's post method will be used to pass credentials to server
  },
  getRoles () {
    return Api().get(prefix + 'roles') // axios object's post method will be used to pass credentials to server
  },
  verifyToken (token) {
    return Api().post(prefix + 'verify', {
      token: token
    })
  },
  generateResetPasswordLink (account, recoveryChannelCodes) {
    return Api().post(prefix + 'generate-reset-password-link', {
      account, recoveryChannelCodes
    })
  },
  resetPassword (token, password) {
    return Api().post(prefix + 'reset-password', {
      token, password
    })
  },
  changePassword (oldPassword, newPassword) {
    return Api().post(prefix + 'change-password', {
      oldPassword, newPassword
    })
  },
  getUserInfo () {
    return Api().get(prefix + '')
  }
}
