let baseURL = ''
if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://menxia.co/api/v1' // Production URL that client will make its requests, use api to differentiate web routing and call for the server back-end.
} else {
  baseURL = 'http://localhost:8081/api/v1' // Development URL that client will make its requests, use api to differentiate web routing and call for the server back-end.
}
module.exports = {
  baseURL: baseURL,
  version: 'client_1.05', // version will be stored on the user side to identify the corresponding version number of use's stored data. If the data structure in "store" is changed, version number must be updated -- this will trigger an forced login to update stored data on the client side.
  imageMaxSizePx: 4080, // the maximum size of image uploaded to aliOss
  TEMPLATE_SHOW_DAYS_IN_ADVANCE: 2, // 提前显示并允许编辑课程的天数
  HTTP_REQUEST_TIMEOUT_MS: 30000 // 规定了HTTP请求超时时间。0: no timeout; 2: timeout 2ms
}
