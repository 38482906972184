import axios from 'axios'
import store from '../store'
import { Base64 } from 'js-base64'
const config = require('../config/config')

export default () => {
  return axios.create({
    baseURL: config.baseURL,
    headers: {
      'content-type': 'application/json',
      'Authorization': _encode()
    },
    timeout: config.HTTP_REQUEST_TIMEOUT_MS
  })
}

function _encode () {
  const token = store.state.token
  const base64 = Base64.encode(token + ':') // base64 主要采用 account:password的形式进行加密，此处只有token所以把它放在account:中，后面没有password所以只加一个:
  return 'Basic ' + base64 // 因为头部需要的格式为 Authorization: Basic base64(account:password), Basic 是指明加密的方式
}
