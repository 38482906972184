import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from '@/plugins/i18n' // language locale management plugin
import VueGtag from 'vue-gtag' // sending data to analytics
import VuejsClipper from 'vuejs-clipper'
import VueRx from 'vue-rx'
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync' // vuex help to manage the data like user login token; vuex-router-sync helps to sync storage with router
import ErrorService from './services/ErrorService'

Vue.config.productionTip = false

// config plugin moment
const moment = require('moment')
require('moment/locale/zh-cn')
Vue.use(require('vue-moment'), {
  moment
})

// sync router state with vue-router
sync(store, router)

// Google analytics
Vue.use(VueGtag, {
  config: {
    id: 'UA-158810240-2',
    params: {
      send_page_view: false
    }
  },
  appName: 'console-menxia',
  pageTrackerScreenviewEnabled: true
}, router)

// install vue-rx
Vue.use(VueRx)

// image clipper
Vue.use(VuejsClipper)

// Handle all Vue errors
Vue.config.errorHandler = (error) => ErrorService.onError(error)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app') // 将Vue的实例对应到 App.vue 中的 <v-app> 标签上, 在此标签中可以使用{{}} expression 来引用 Vue中的数据。
/**
 * 另外，Vue还有v-bind的方法来绑定数据，可以在html中这样使用
 * <img v-bind:src="img">
 * 此处使用 v-bind 修饰src属性后，程序会将后面引号中的img与Vue当中同名变量相绑定。
 * 由于此功能比较常用也可以使用其short-hand
 * <img :src="img">
 */
