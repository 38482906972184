<template>
<div>
  <h1>图片处理</h1>
  <v-btn>
    <clipper-upload v-model="imgURL">upload image</clipper-upload>
  </v-btn>
  <v-btn @click="getResult">clip image</v-btn>
  <clipper-fixed class="my-clipper" ref="clipper" :src="imgURL" preview="my-preview" :ratio='4/3'>
      <div class="placeholder" slot="placeholder">No image</div>
  </clipper-fixed>
  <div>
      <div>preview:</div>
      <clipper-preview name="my-preview" class="my-clipper">
          <div class="placeholder" slot="placeholder">preview area</div>
      </clipper-preview>
  </div>
  <div>
      <div>result:</div>
      <img class="result" :src="resultURL" alt="">
  </div>
  <clipper-basic class="my-clipper" :src="img" />
  <h1>文档签署</h1>
  <v-btn @click="modify" :loading="btnLoading">在活动协议基础上修改pdf</v-btn>
</div>
</template>

<script>
import util from '@/util'
import resizeImage from '@/plugins/imageResize.js'
import { degrees, PDFDocument, rgb } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
const download = require('downloadjs')

const config = require('../config/config')
export default {
  components: {
  },
  data () {
    return {
      imgURL: '',
      resultURL: '',
      img: '',
      btnLoading: false
    }
  },
  methods: {
    getResult: async function () { // crop image
      const canvas = this.$refs.clipper.clip() // call component's clip method
      console.log('canvas:')
      console.log(canvas)
      this.resultURL = canvas.toDataURL('image/jpeg', 1) // canvas->image
      console.log('this.resultURL:')
      console.log(this.resultURL)

      let clippedImgFile = null
      canvas.toBlob(async (blob) => {
        console.log('blob:')
        console.log(blob)
        clippedImgFile = new File([blob], 'clippedImage.jpg', { type: blob.type })
        console.log('clippedImgFile:')
        console.log(clippedImgFile)
        // compress the image if necessary
        const resizedImage = await resizeImage({ file: clippedImgFile, maxSize: config.imageMaxSizePx })
        // const imgFileUrl = URL.createObjectURL(resizedImage)
        const imgFile = new File([resizedImage], clippedImgFile.name, { type: resizedImage.type }) // convert blob to file for uploading
        let { imgUrl, fileName } = await util.uploadToAliOss(imgFile)
        console.log(imgUrl)
        console.log(fileName)
      }, 'image/jpeg', 0.95)
    },
    change ({ coordinates, canvas }) {
      console.log(coordinates, canvas)
    },
    modify: async function () {
      this.btnLoading = true // 控制按扭 loading 状态。
      // 取得协议模版
      const existingPdfBytes = await fetch('https://qiuyao-pub-read.oss-cn-shenzhen.aliyuncs.com/Contract_Template_Course_System_20210407182100.pdf').then(res => res.arrayBuffer())
      // console.log(`existingPdfBytes: ${existingPdfBytes}`)
      // Load a PDFDocument from the existing PDF bytes
      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      // 注册嵌入字体方法，以便于后续嵌入中文字体
      pdfDoc.registerFontkit(fontkit)

      // 嵌入中文字体，以在后续在pdf中使用中文。
      const fontBytes = await fetch('https://qiuyao-pub-read.oss-cn-shenzhen.aliyuncs.com/FangZhengHeiTiJianTi.ttf').then((res) => res.arrayBuffer()) // 下载字体文件并转化为arrayBuffer
      const fangzhengheitijiantiFont = await pdfDoc.embedFont(fontBytes) // 将字体嵌入pdf中

      // 下载并嵌入公章文件。
      const stampBytes = await fetch('https://qiuyao-pub-read.oss-cn-shenzhen.aliyuncs.com/Official_Seal_Menxia_202104071847.png').then((res) => res.arrayBuffer())
      const stampImage = await pdfDoc.embedPng(stampBytes)

      // Get the specified page of the document
      const pages = pdfDoc.getPages()
      const secondPage = pages[1]
      const { width, height } = secondPage.getSize() // Get the width and height of the page
      console.log(`pdf document width: ${width} ;height: ${height}`) // A4 pdf document width: 596 ;height: 843 0 coordinate starts from left bottom corner

      // 按区域录入必要的文字信息，项目名称
      secondPage.drawText('语言表达力训练 [I] - LONGMAN Welcome to English: 1A | 香港小学英语教材', {
        x: 136,
        y: 278.7,
        font: fangzhengheitijiantiFont,
        size: 12,
        color: rgb(0, 0, 1),
        rotate: degrees(0)
      })

      // 输入必要的文字信息，缴费
      secondPage.drawText('8888', {
        x: 136,
        y: 254,
        font: fangzhengheitijiantiFont,
        size: 12,
        color: rgb(0, 0, 1),
        rotate: degrees(0)
      })
      // 输入必要的文字信息，课时
      secondPage.drawText('192', {
        x: 281,
        y: 254,
        font: fangzhengheitijiantiFont,
        size: 12,
        color: rgb(0, 0, 1),
        rotate: degrees(0)
      })
      // 输入必要的文字信息，性质
      secondPage.drawText('固定进度', {
        x: 451,
        y: 254,
        font: fangzhengheitijiantiFont,
        size: 12,
        color: rgb(0, 0, 1),
        rotate: degrees(0)
      })
      // 输入必要的文字信息，签字日期(学员)
      secondPage.drawText('2021/04/07 10:30', {
        x: 433,
        y: 173,
        font: fangzhengheitijiantiFont,
        size: 12,
        color: rgb(0, 0, 1),
        rotate: degrees(0)
      })
      // 输入必要的文字信息，签字日期(教师)
      secondPage.drawText('2021/04/07 19:30', {
        x: 433,
        y: 123,
        font: fangzhengheitijiantiFont,
        size: 12,
        color: rgb(0, 0, 1),
        rotate: degrees(0)
      })
      // 输入必要的文字信息，学员称呼(李国娟/朱祉灿(灿灿 Andy))
      secondPage.drawText('李国娟/朱祉灿(灿灿 Andy)', {
        x: 195,
        y: 145,
        font: fangzhengheitijiantiFont,
        size: 7,
        color: rgb(0, 0, 1),
        rotate: degrees(0)
      })
      // 输入必要的文字信息，学员手机(李国娟/朱祉灿(灿灿 Andy))
      secondPage.drawText('李国娟: +86-13713632494', {
        x: 306,
        y: 145,
        font: fangzhengheitijiantiFont,
        size: 7,
        color: rgb(0, 0, 1),
        rotate: degrees(0)
      })
      // 输入必要的文字信息，教师称呼
      secondPage.drawText('孟宁(Michelle) 老师', {
        x: 195,
        y: 91,
        font: fangzhengheitijiantiFont,
        size: 7,
        color: rgb(0, 0, 1),
        rotate: degrees(0)
      })
      // 输入必要的文字信息，教师手机
      secondPage.drawText('孟宁: +86-13713632581', {
        x: 306,
        y: 91,
        font: fangzhengheitijiantiFont,
        size: 7,
        color: rgb(0, 0, 1),
        rotate: degrees(0)
      })
      // 盖章
      const scaledStampImage = stampImage.scaleToFit(112.5, 112.5)
      secondPage.drawImage(stampImage, {
        x: 400,
        y: 76.6,
        height: scaledStampImage.height,
        width: scaledStampImage.width
      })

      // Serialize the PDFDocument to bytes (a Uint8Array)
      const pdfBytes = await pdfDoc.save()
      // console.log(pdfBytes)
      download(pdfBytes, 'pdf-lib_modification_example.pdf', 'application/pdf') // Trigger the browser to download the PDF document
      // For example, `pdfBytes` can be:
      //   • Written to a file in Node
      //   • Downloaded from the browser
      //   • Rendered in an <iframe>
      this.btnLoading = false
    }
  }
}
</script>
<style scoped>
.my-clipper {
  width: 100%;
  max-width: 700px;
}
.placeholder {
  text-align: center;
  padding: 20px;
  background-color: lightgray;
}
</style>
