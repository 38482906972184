import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import zhHans from 'vuetify/lib/locale/zh-Hans'

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles
import 'vuetify/dist/vuetify.min.css'

// Vuetify Object (as described in the Vuetify 2 documentation)
const vuetify = new Vuetify()

Vue.use(Vuetify)

// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md'
})

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    themes: {
      light: {
        primary: '#FF163E', // red
        // primary: '#0796F8',
        secondary: '#E8099E', // purple
        // secondary: '#3107D9',
        success: '#3cd1c3',
        info: '#ffaa2c',
        error: '#E8099E'
        // anchor: '#8c9eff',
      }
    }
  },
  lang: {
    locales: { zhHans },
    current: 'zhHans'
  }
})
