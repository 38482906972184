// 命名规则见Zerg/models/file.js

import Api from '@/services/Api' // axois object created in api.js
import axios from 'axios'
import consola from 'consola'

export default { // export its register method
  getUploadPolicyAndSignature ({ publicRead }) {
    consola.info('[AliOssService getUploadPolicyAndSignature] Sending request via GET method to server, waiting for server response ...')
    return Api().get('ali-oss/upload-policy-and-signature', { params: { publicRead } })
  },
  upload (host, formData) {
    consola.info(`uploading to ali oss ...`)
    consola.log(`formData: ${JSON.stringify(formData)}`)
    return axios.post(host, formData, { 'Content-Type': 'multipart/form-data' })
  },
  /**
   * upload file to Alioss and return its url.
   * @param {Object} file
   */
  async uploadToAliOss ({ file, publicRead }) {
    console.log(`uploadToAliOss: filename: ${file.name}; publicRead: ${publicRead}`)
    // getting policy: details (bucket, host, etc) will be configured at backend
    const signatureData = await this.getUploadPolicyAndSignature({ publicRead })
    const { OSSAccessKeyId, host, policy, signature, serverTimeMs } = signatureData.data
    // construct file name
    const fullName = file.name
    const suffix = fullName.substr(fullName.indexOf('.')) // 提取文件后缀
    const name = fullName.replace(/\.[^/.]+$/, '') // 提取文件名
    const filename = name + '_' + serverTimeMs + suffix // 组成新文件名
    // construct formData(policy, key, signature, filename, file etc.) for uploading (need host and formData)
    const formData = new FormData()
    formData.append('OSSAccessKeyId', OSSAccessKeyId)
    formData.append('policy', policy)
    formData.append('signature', signature)
    formData.append('key', filename)
    formData.append('success_action_status', 200)
    formData.append('file', file, filename)
    // upload
    try {
      let res = await this.upload(host, formData)
      console.log(`上传成功! res: ${JSON.stringify(res)}`)
    } catch (e) {
      console.error(e)
    }
    let imgUrl = host + '/' + filename
    return {
      imgUrl,
      fileName: filename
    }
  }
}
