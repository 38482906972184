const RoleCodes = Object.freeze({
  TUTOR: 1,
  STUDENT: 2,
  HOST: 3,
  ADMIN: 4,
  ORGANIZATION: 5,
  AFFILIATE: 6,
  ORG_GROUP_MGR: 7,
  MANAGER: 8,
  SYSTEM: 9,
  INVITER: 10,
  TRAINER: 11
})

export { RoleCodes }
