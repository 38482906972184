<template>
  <v-container class="ml-5 mr-5 my-5">
    <v-layout row wrap>
        <v-form name="register-form" ref="registerUserForm" autocomplete='off' v-model="isFormVisiblyValid" lazy-validation>
          <v-card class="mb-5" flat>
            <v-text-field
              label="新密码"
              type="password"
              name="password"
              hint="至少6位，仅可以使用数字及大小写字母"
              :rules ="passwordRules"
              v-model="password"
            ></v-text-field>
            <v-text-field
              label="确认密码"
              type="password"
              name="passwordConfirm"
              :rules ="passwordConfirmRules"
              v-model="passwordConfirm"
            ></v-text-field>
          </v-card>
          <v-btn color="primary" :disabled="!isFormVisiblyValid || requestSent" @click="resetPassword(token, password)">
            重置密码
          </v-btn>
        </v-form>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
    >
      {{ snackbarMsg }}
      <v-btn
        color="primary"
        text
        @click="snackbar = false"
      >
        知道了
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
import consola from 'consola'

export default {
  components: {
  },
  computed: {
  },
  data () {
    return {
      isFormVisiblyValid: true,
      password: '',
      passwordRules: [
        v => /^[a-zA-Z0-9]{6,16}$/.test(v) || '密码只能包含数字及大小写字母，并为6-16位'
      ],
      passwordConfirm: '',
      passwordConfirmRules: [
        v => v === this.password || '密码两次输入不一致'
      ],
      token: '',
      requestSent: false,
      snackbar: false,
      snackbarTimeout: 5000,
      snackbarMsg: ''
    }
  },
  async mounted () {
    this.token = this.$store.state.route.query.token
    consola.info(`#Page: password-reset. token: ${this.token}`)
  },
  methods: {
    async resetPassword (token, password) {
      this.requestSent = true
      try {
        const res = await AuthenticationService.resetPassword(token, password)
        this.showSnackBar(res.data.msg + ' 正在跳转登录页面...', this.snackbarTimeout)
        setTimeout(() => {
          this.$router.push('login')
        }, this.snackbarTimeout)
      } catch (error) {
        consola.log(`error: ${JSON.stringify(error.response)}`)
        this.showSnackBar(error.response.data.msg, this.snackbarTimeout)
        this.requestSent = false
      }
    },
    showSnackBar (snackbarMsg, timeout) {
      this.snackbar = true
      this.snackbarTimeout = timeout
      this.snackbarMsg = snackbarMsg
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
