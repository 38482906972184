import LessonService from '@/services/LessonService'
import AliOssService from '@/services/AliOssService'
import { ClassStatusLookup } from './enums'
import ProgramService from './services/ProgramService'

export default {
  // judge if element 'needle' is in an array 'haystack'
  inArray (needle, haystack) {
    var length = haystack.length
    for (var i = 0; i < length; i++) {
      if (haystack[i] === needle) return true
    }
    return false
  },
  async getLessonSessionsByLessonTemplateId (lessonTemplateId) {
    console.log('\ngetLessonSessionsByLessonTemplateId starts ... \nlessonTemplateId: ', lessonTemplateId)
    const res = await LessonService.getLessonSessionsByLessonTemplateId(lessonTemplateId)
    console.log('Retrieve lessonSessions successfully :', res.data.lessonSessions)
    return res.data.lessonSessions
  },

  // async foreach
  async asyncForEach (array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  },
  /**
   * 字符隐藏处理
   * @param {Object} 'str': 需要处理的字符串 'maskPercent': 隐藏的百分比 0 ~ 100(隐藏正中部，在没有设定前后隐藏位数时生效) 'beginningDigitsUnmask': 前部显示位数, 'endDigitsUnmask': 后部显示位数, 'mask'：替换字符 (默认：'*')
   *
   * @returns {string} 处理后的字符
   */
  maskString ({ str, maskPercent = 30, beginningDigitsUnmask, endDigitsUnmask, mask = '*' }) {
    console.log(`[maskString] starts ... str: ${str}; beginningDigitsUnmask: ${beginningDigitsUnmask}; endDigitsUnmask: ${endDigitsUnmask}; maskPercent: ${maskPercent}`)
    if (maskPercent) {
      if (!beginningDigitsUnmask) beginningDigitsUnmask = Math.floor(str.length * (100 - maskPercent) / 200)
      if (!endDigitsUnmask) endDigitsUnmask = Math.floor(str.length * (100 - maskPercent) / 200)
    }
    console.log(`[maskString] beginningDigitsUnmask: ${beginningDigitsUnmask}; endDigitsUnmask: ${endDigitsUnmask}; maskPercent: ${maskPercent}`)
    if (beginningDigitsUnmask + endDigitsUnmask >= str.length) { return str }
    const beginningPortion = str.substring(0, beginningDigitsUnmask)
    const endPortion = str.substring(str.length - endDigitsUnmask)
    const maskPortion = mask.repeat(str.length - beginningDigitsUnmask - endDigitsUnmask)
    console.log(`[maskString] beginningPortion: ${beginningPortion}; maskPortion: ${maskPortion}; endPortion: ${endPortion}`)
    return (beginningPortion + maskPortion + endPortion)
  },
  /**
   * upload file to Alioss and return its url.
   * @param {Object} file
   */
  async uploadToAliOss (file) {
    console.log(`uploadToAliOss: filename: ${file.name}`)
    // getting policy: details (bucket, host, etc) will be configured at backend
    const signatureData = await AliOssService.getUploadPolicyAndSignature()
    const { OSSAccessKeyId, host, policy, signature, serverTimeMs } = signatureData.data
    // construct file name
    const fullName = file.name
    const suffix = fullName.substr(fullName.indexOf('.')) // 提取文件后缀
    const name = fullName.replace(/\.[^/.]+$/, '') // 提取文件名
    const filename = serverTimeMs + '_' + name + suffix // 组成新文件名
    // construct formData(policy, key, signature, filename, file etc.) for uploading (need host and formData)
    const formData = new FormData()
    formData.append('OSSAccessKeyId', OSSAccessKeyId)
    formData.append('policy', policy)
    formData.append('signature', signature)
    formData.append('key', filename)
    formData.append('success_action_status', 200)
    formData.append('file', file, filename)
    // upload
    try {
      let res = await AliOssService.upload(host, formData)
      console.log(`上传成功! res: ${JSON.stringify(res)}`)
    } catch (e) {
      console.error(e)
    }
    let imgUrl = host + '/' + filename
    return {
      imgUrl,
      fileName: filename
    }
  },
  blobToFile (theBlob, fileName) {
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date()
    theBlob.name = fileName
    return theBlob
  },
  // 通过项目id，返回在该项目下建立班级的教师列表,要求该班级处于活跃状态
  async programIdToTutors (programId) {
    let classes = []
    let tutors = []
    // 根据所选教师 请求 classInfo
    const resClasses = await ProgramService.getClasses({
      programIds: [programId],
      attachTutors: true,
      statusCodes: [
        ClassStatusLookup.ACTIVE
      ]
    })
    classes = resClasses.data
    console.log(`classes under programId: ${programId}:`)
    console.log(classes)

    // 准备需要本项目相关班级所有教师列表
    classes.forEach((singleClass) => {
      if (singleClass.tutors) {
        singleClass.tutors.forEach((tutor) => {
          const tutorIds = tutors.map(tutor => tutor.tutorId)
          if (tutorIds.includes(tutor.tutorId)) {
          } else {
            tutors.push(tutor)
          }
        })
      }
    })
    return tutors
  }
}
