import Api from '@/services/Api' // axois object created in api.js

export default { // export its register method
  getLessonTemplates ({ lessonTemplateIds = [], templateTutorIds = [], programIds = [], organizationIds = [], programStatusCodes = [], beforeTime, afterTime, offset = 0, limit = null }) {
    console.log('Services/LessonService.getLessonTemplatesByTutorId starts... tutorId: ', templateTutorIds,
      'programId:', programIds,
      'programStatusCode', programStatusCodes
    )
    return Api().get('lesson/templates', { params: { lessonTemplateIds, templateTutorIds, programIds, organizationIds, programStatusCodes, beforeTime, afterTime, offset, limit } }) // axios object's get method will be used to pass credentials to server
  },
  getLessonSessions ({
    lessonTemplateIds,
    tutorIds,
    programIds,
    statusCodes,
    lessonIds,
    organizationIds,
    classIds,
    sinceDateTime,
    untilDateTime,
    findOne
  }) {
    console.log('Services/LessonService.getLessonSessions starts... lessonTemplateId: ', lessonTemplateIds,
      'tutorIds: ', tutorIds,
      'programIds', programIds,
      'lessonIds', lessonIds,
      'statusCodes:', statusCodes
    )
    return Api().get('lesson/sessions', { params: {
      lessonTemplateIds,
      tutorIds,
      programIds,
      statusCodes,
      lessonIds,
      organizationIds,
      classIds,
      sinceDateTime,
      untilDateTime,
      findOne
    } }) // axios object's get method will be used to pass credentials to server
  },
  getLessonEnrollments ({ lessonIds, studentIds, confirmationCodes }) {
    console.log('Services/LessonService.getLessonEnrollments starts... lessonIds: ', lessonIds,
      'studentIds: ', studentIds,
      'confirmationCodes: ', confirmationCodes
    )
    return Api().get('lesson/enrollments', { params: { lessonIds, studentIds, confirmationCodes } }) // axios object's get method will be used to pass credentials to server
  },
  getLessonTutorStatusLookupItems () {
    console.log('Services/LessonService.getLessonTutorStatusLookupItems starts... ')
    return Api().get('lesson/tutor-status-lookup-items') // axios object's get method will be used to pass credentials to server
  },
  getLessonVenueStatusLookupItems () {
    console.log('Services/LessonService.getLessonVenueStatusLookupItems starts... ')
    return Api().get('lesson/venue-status-lookup-items') // axios object's get method will be used to pass credentials to server
  },
  createLessonTemplate (lessonTemplate) {
    return Api().post('lesson/template-create', lessonTemplate)
  },
  createLessonSession (lessonSession) {
    return Api().post('lesson/session-create', {
      start_time: lessonSession.start_time,
      venue_id: lessonSession.venue_id,
      venue_status_code: lessonSession.venue_status_code,
      tutor_id: lessonSession.tutor_id,
      tutor_status_code: lessonSession.tutor_status_code,
      lesson_template_id: lessonSession.lesson_template_id,
      class_id: lessonSession.class_id,
      status_code: lessonSession.status_code
    })
  },
  lessonEnrollmentInit ({ lessonId }) {
    return Api().post('lesson/session-enrollment-init', {
      lessonId
    })
  },
  upsertLessonSessionBatch ({ templatesInfo }) {
    return Api().post('lesson/session-upsert-batch', {
      templatesInfo
    })
  },
  updateLessonEnrollmentAttendanceStatus (lessonEnrollmentInfo) {
    console.log('Services/LessonService.updateLessonEnrollmentAttendanceStatus starts... checkInInfo: ', lessonEnrollmentInfo)
    let enrollmentAttendanceStatus = []
    lessonEnrollmentInfo.forEach(enrollment => {
      enrollmentAttendanceStatus.push({
        'id': enrollment.lesson_enrollment_id,
        'status_code': enrollment.attendance_code
      })
    })
    return Api().put('lesson/session-enrollments-update', enrollmentAttendanceStatus) // axios object's get method will be used to pass credentials to server
  },
  updateLesson (lesson) {
    console.log('Services/LessonService.updateLesson starts... lesson: ', lesson)
    return Api().put('lesson/update', lesson) // axios object's get method will be used to pass credentials to server
  },
  lessonIncomeAllocation (lessonId) {
    console.log('Services/LessonService.lessonIncomeAllocation starts... lessonId: ', lessonId)
    return Api().post('lesson/income-distribution', { 'lesson_id': lessonId })
  },
  studentAttendanceStatusFeedback (lessonEnrollmentId, confirmationCode = 0) {
    return Api().put('lesson/student-confirm', { 'lesson_enrollment_id': lessonEnrollmentId, 'lesson_enrollment_confirmation_code': confirmationCode })
  },
  deleteLesson (lessonId) {
    console.log('Services/LessonService.deleteLesson starts... lessonId: ', lessonId)
    return Api().delete('lesson/session-delete', { params: { lesson_id: lessonId } }) // axios object's get method will be used to pass credentials to server
  },
  deleteLessonTemplate (lessonTemplateId) {
    return Api().delete('lesson/template-delete', { params: { lesson_template_id: lessonTemplateId } })
  }
  // createLessonSessionEnrollment (lessonSessionIds) {
  //   return Api().post('createLessonSessionEnrollment', { lessonSessionIds: lessonSessionIds })
  // },
  // getLessonInfoByLessonId (lessonId) {
  //   console.log('Services/LessonService.getLessonInfoByLessonId starts... lessonId: ', lessonId)
  //   return Api().get('getLessonInfoByLessonId', { params: { lessonId: lessonId } }) // axios object's get method will be used to pass credentials to server
  // },
  // getLessonSessionsbyTutorId (tutorId, statusCodes) {
  //   console.log('Services/LessonService.getLessonSessionsbyTutorId starts... tutorId: ', tutorId, ', statusCodes: ', statusCodes)
  //   return Api().get('getLessonSessionsbyTutorId', { params: { tutorId: tutorId, statusCodes: statusCodes } }) // axios object's get method will be used to pass credentials to server
  // }
}
