import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

const config = require('../config/config')

export default class ErrorService {
  constructor () {
    this.initHandler()
  }

  static onError (error) {
    const response = error.response
    if (response && response.status >= 400 && response.status < 405) {
      // You can handle this differently
      this.displayErrorAlert(response.data.msg)
      console.log('response.data')
      console.log(response.data)
      ErrorService.sentryLogEngine(error)
      return false
    }
    if (error.code === 'ECONNABORTED') { // 该请求超时由HTTP_REQUEST_TIMEOUT_MS设定，为axios 请求超时错误
      this.displayErrorAlert(`网络连接错误(超时)`)
      console.log(`请求超过设定时限：${config.HTTP_REQUEST_TIMEOUT_MS}毫秒`)
      // this.$store.dispatch('setQueryingState', false)
    }
    // Send Error to Log Engine e.g LogRocket
    ErrorService.logRocketLogEngine(error)

    // set queryingState
    // this.$store.dispatch('setQueryingState', false)
  }

  static onWarn (error) {
    // Send Error to Log Engine e.g LogRocket
    this.logRocketLogEngine(error)
  }

  static onInfo (error) {
    // You can handle this differently
    this.sentryLogEngine(error)
  }

  static onDebug (error) {
    const response = error.response
    if (response && response.status >= 400 && response.status < 405) {
      // You can handle this differently
      this.sentryLogEngine(error)
      return false
    }
    // Send Error to Log Engine e.g LogRocket
    this.logRocketLogEngine(error)
  }

  static initHandler () {
    const scope = this
    window.onerror = (message, url, lineNo, columnNo, error) => {
      console.log(error, 'test')
      if (error) {
        scope.onError(error)
        console.log(message, url, lineNo, columnNo, error)
      }
    }
  }

  static displayErrorAlert (message) {
    Swal.fire({
      title: '注意!',
      text: message,
      icon: 'error',
      confirmButtonText: '好的',
      cancelButtonText: '取消'
    })
  }

  static logRocketLogEngine (error) {
    // Implement LogRocket Engine here
    console.log(error, 'LogRocket')
  }

  static sentryLogEngine (error) {
    // Implement Sentry Engine here
    console.log(error, 'Sentry')
  }
}
