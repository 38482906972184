import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from './views/Dashboard.vue'
import PasswordReset from '@/views/PasswordReset'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'root',
      component: Dashboard
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/register-user',
      name: 'register-user',
      component: () => import('./views/RegisterUser.vue')
    },
    {
      path: '/register-tutor',
      name: 'register-tutor',
      component: () => import('./views/RegisterTutor.vue')
    },
    {
      path: '/register-student',
      name: 'register-student',
      component: () => import('./views/RegisterStudent.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue')
    },
    {
      path: '/manage/programs',
      name: 'programs',
      component: () => import('./views/Programs.vue')
    },
    {
      path: '/manage/programs/upsert',
      name: 'upsert-program',
      component: () => import('@/views/ProgramUpsert')
    },
    {
      path: '/manage/programs/purchase-packages-upsert',
      name: 'program-purchase-packages-upsert',
      component: () => import('@/views/ProgramPurchasePackagesUpsert')
    },
    {
      path: '/manage/classes',
      name: 'classes',
      component: () => import('@/views/Classes')
    },
    {
      path: '/manage/class/create',
      name: 'create-class',
      component: () => import('@/views/CreateClass')
    },
    {
      path: '/manage/student-details',
      name: 'student-details',
      component: () => import('@/views/StudentDetails')
    },
    {
      path: '/manage/students',
      name: 'students',
      component: () => import('@/views/Students')
    },
    {
      path: '/lesson/templates',
      name: 'lesson-templates',
      component: () => import('@/views/LessonTemplates')
    },
    {
      path: '/lesson/templates-upcoming',
      name: 'lesson-templates-upcoming',
      component: () => import('@/views/LessonTemplatesUpcoming')
    },
    {
      path: '/lesson/template/edit',
      name: 'edit-lesson-template',
      component: () => import('@/views/EditLessonTemplate')
    },
    {
      path: '/lesson/template/enrollment',
      name: 'lesson-enrollment',
      component: () => import('@/views/LessonEnrollment')
    },
    {
      path: '/lesson/template/check-in',
      name: 'check-in',
      component: () => import('@/views/CheckIn')
    },
    {
      path: '/lesson/finalization',
      name: 'lesson-finalization',
      component: () => import('@/views/LessonFinalization')
    },
    {
      path: '/income/organization',
      name: 'organization-income',
      component: () => import('@/views/OrganizationIncome')
    },
    {
      path: '/income/host',
      name: 'host-income',
      component: () => import('@/views/HostIncome')
    },
    {
      path: '/my/membership',
      name: 'membership',
      component: () => import('@/views/Membership')
    },
    {
      path: '/my/transaction',
      name: 'transaction',
      component: () => import('@/views/UserTransaction')
    },
    {
      path: '/password-reset-link',
      name: 'password-reset-link',
      component: () => import('@/views/PasswordResetLink')
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      component: PasswordReset // 不使用lazy loading，因为会影响 state.route 在启动时的识别
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/Account')
    },
    {
      path: '/password-change',
      name: 'password-change',
      component: () => import('@/views/PasswordChange')
    },
    {
      path: '/organizations',
      name: 'organizations',
      component: () => import('@/views/Organizations')
    },
    {
      path: '/lesson-scheduler',
      name: 'lesson-scheduler',
      component: () => import('@/views/LessonScheduler')
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/Calendar')
    }
  ]
})
