<template>
  <nav v-if="$store.state.isUserLoggedIn">
    <!-- <v-toolbar flat app extended extension-height="1"> -->
    <v-app-bar>
      <v-app-bar-nav-icon v-if="$store.state.isUserLoggedIn" class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="title grey--text">
        秋邀
      </v-toolbar-title>
      <div v-if="$store.state.isUserLoggedIn">
        <v-tooltip
          bottom
          v-for="(status, indexStatus) in platformSpecialStatus" v-bind:key="`status-${indexStatus}`"
        >
          <template v-slot:activator="{ on }">
            <v-icon color="grey" dark v-on="on" class="px-1">local_cafe</v-icon>
          </template>
          <span>{{status.status}}状态 | {{status.description}}</span>
        </v-tooltip>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-chip
        v-if="$store.state.isUserLoggedIn"
        class="ma-2"
        color="primary"
        text-color="white"
        @click="navigateTo({name: 'membership'})"
        small
      >
        会员: {{$store.state.account.organizations[0].membership_level_code}}级权益
      </v-chip> -->
      <v-toolbar-items>
        <v-btn
          text
          v-if="!$store.state.isUserLoggedIn"
          @click="navigateTo({name: 'login'})">
          登录
        </v-btn>
        <!-- <v-btn flat
          v-if="!$store.state.isUserLoggedIn"
          @click="navigateTo({name: 'register-user'})">
          立即注册
        </v-btn> -->
        <v-menu v-if="$store.state.isUserLoggedIn" offsetY>
          <template v-slot:activator="{ on }">
            <v-btn text color="grey" v-on="on">
              <v-icon left> expand_more </v-icon>
              <span>您好，{{$store.state.user.realName ? $store.state.user.realName : $store.state.user.userName }}</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-title>
                <v-icon left>power_settings_new</v-icon>
                退出
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if='organizationId' @click="navigateTo({name: 'membership'})">
              <v-list-item-title>
                <v-icon left>card_membership</v-icon>
                会员等级
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-progress-linear :active="$store.state.queryingState" :indeterminate="$store.state.queryingState" absolute bottom></v-progress-linear>
    </v-app-bar>

    <!-- 左侧菜单 -->
    <v-navigation-drawer
      v-model="drawer"
      app
      dark
      src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
    >
      <v-list>
        <template v-for="(link, linkIndex) in links">
          <v-list-group
            v-if="link.accessRoleCodes.filter(value => $store.state.roleCodes.includes(value)).length > 0"
            :key="link.title"
            v-model="link.active"
            :prepend-icon="link.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="link.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(subLink, subLinkIndex) in link.subLinks"
              :key="subLink.title"
              router :to="subLink.route"
              @click="navigateToLinkWithQuery({ linkIndex, subLinkIndex, route: subLink.route })"
            >
              <v-list-item-content>
                <v-list-item-title v-text="subLink.title"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon>{{ subLink.action }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
// import i18n from '@/plugins/i18n'
import PlatformService from '@/services/PlatformService'
import { RoleCodes } from '../enums'

export default {
  async mounted () {
    // 检查平台特殊状态
    const resPlatformSpecialStatus = await PlatformService.getPlatformSpecialStatus({ dateTime: new Date() })
    this.platformSpecialStatus = resPlatformSpecialStatus.data
    console.info(`平台特殊状态检查：${this.platformSpecialStatus}`)
    // 检查登录人员身份信息, 并更新导航项链接
    if (this.$store.state.isUserLoggedIn) {
      if (this.$store.state.account.organizations) { // 机构角色
        const organizationIds = this.$store.state.account.organizations.map(organization => organization.id)
        this.organizationId = organizationIds[0]
      }
      if (this.$store.state.account.tutor) {
        this.tutorId = this.$store.state.account.tutor.id
        console.log(`用户具有私教身份，Id: ${this.tutorId}`)
      }
      if (this.$store.state.account.manager) {
        this.managerId = this.$store.state.account.manager.id
      }
      if (this.$store.state.account.inviter) {
        this.inviterId = this.$store.state.account.inviter.id
      }
      if (this.$store.state.account.trainer) {
        this.trainerId = this.$store.state.account.trainer.id
      }
      if (this.$store.state.account.coach) {
        this.coachId = this.$store.state.account.coach.id
      }
      if (this.$store.state.account.admin) {
        this.adminId = this.$store.state.account.admin.id
      }
    }
  },
  data () {
    return {
      drawer: true,
      alert: true,
      platformSpecialStatus: [],
      tutorId: null,
      organizationId: null,
      managerId: null,
      trainerId: null,
      adminId: null,
      coachId: null,
      links: [
        // 机构管理
        {
          action: 'school',
          title: '机构管理',
          subLinks: [
            { title: '课程表', name: 'calendar', route: `/calendar`, accessRoleCodes: [RoleCodes.ORGANIZATION] },
            { title: '项目列表', name: 'programs', route: `/manage/programs`, accessRoleCodes: [RoleCodes.ORGANIZATION] },
            { title: '班级列表', name: 'classes', route: `/manage/classes`, accessRoleCodes: [RoleCodes.ORGANIZATION] },
            { title: '学员列表', name: 'students', route: `/manage/students`, accessRoleCodes: [RoleCodes.ORGANIZATION] },
            { title: '排课', name: 'lesson-scheduler', route: `/lesson-scheduler`, accessRoleCodes: [RoleCodes.ORGANIZATION] },
            { title: '签到', name: 'lesson-templates', route: `/lesson/templates`, accessRoleCodes: [RoleCodes.ORGANIZATION] },
            { title: '结课', name: 'lesson-finalization', route: `/lesson/finalization`, accessRoleCodes: [RoleCodes.ORGANIZATION] },
            { title: '会员权益', name: 'membership', route: `/my/membership`, accessRoleCodes: [RoleCodes.ORGANIZATION] },
            { title: '基本信息', name: 'account', route: `/account`, accessRoleCodes: [RoleCodes.ORGANIZATION] }
          ],
          accessRoleCodes: [ RoleCodes.ORGANIZATION ]
        },
        // 教师身份
        {
          action: 'integration_instructions',
          title: '私教身份',
          subLinks: [
            { title: '课程表', name: 'calendar', route: `/calendar`, accessRoleCodes: [RoleCodes.TUTOR] },
            { title: '班级', name: 'classes', route: `/manage/classes`, accessRoleCodes: [RoleCodes.TUTOR] },
            { title: '学员', name: 'students', route: `/manage/students`, accessRoleCodes: [RoleCodes.TUTOR] },
            { title: '签到', name: 'lesson-templates', route: `/lesson/templates`, accessRoleCodes: [RoleCodes.TUTOR] },
            { title: '结课', name: 'lesson-finalization', route: `/lesson/finalization`, accessRoleCodes: [RoleCodes.TUTOR] }
          ],
          accessRoleCodes: [ RoleCodes.TUTOR ]
        },
        // 校区经理
        {
          action: 'groups',
          title: '秋邀 Manager',
          subLinks: [
            { title: '课程表', name: 'calendar', route: `/calendar`, accessRoleCodes: [RoleCodes.MANAGER] },
            { title: '机构列表', route: '/organizations', accessRoleCodes: [RoleCodes.MANAGER] },
            { title: '学员列表', name: 'students', route: `/manage/students`, accessRoleCodes: [RoleCodes.MANAGER] }
          ],
          accessRoleCodes: [ RoleCodes.MANAGER ]
        },
        // 邀请人
        // {
        //   action: 'groups',
        //   title: '秋邀 Inviter',
        //   subLinks: [
        //     { title: '我的邀请', route: '/students', accessRoleCodes: [RoleCodes.INVITER] }
        //   ],
        //   accessRoleCodes: [ RoleCodes.INVITER ]
        // },
        // 超级管理员
        {
          action: 'groups',
          title: '秋邀 Admin',
          subLinks: [
            { title: '课程表', name: 'calendar', route: `/calendar`, accessRoleCodes: [RoleCodes.ADMIN] },
            { title: '机构列表', route: '/organizations', accessRoleCodes: [RoleCodes.ADMIN] },
            { title: '学员列表', name: 'students', route: `/manage/students`, accessRoleCodes: [RoleCodes.ADMIN] }
          ],
          accessRoleCodes: [ RoleCodes.ADMIN ]
        },
        // 个人账户
        {
          action: 'account_circle',
          title: '我的收入',
          subLinks: [
            { title: '记录', name: 'transaction', route: '/my/transaction' }
          ],
          accessRoleCodes: [
            RoleCodes.TUTOR,
            RoleCodes.HOST,
            RoleCodes.ORGANIZATION,
            RoleCodes.MANAGER,
            RoleCodes.SYSTEM,
            RoleCodes.INVITER,
            RoleCodes.TRAINER
          ]
        }
      ]
    }
  },
  methods: {
    navigateTo (route) {
      this.$router.push(route) // the $router is attached by default
    },
    navigateToLinkWithQuery ({ linkIndex, subLinkIndex, route }) {
      let query = {}
      let roleCodes = this.links[linkIndex].subLinks[subLinkIndex].accessRoleCodes
      if (roleCodes.includes(RoleCodes.TUTOR)) {
        query.tutorId = this.tutorId
      }
      if (roleCodes.includes(RoleCodes.ORGANIZATION)) {
        query.organizationId = this.organizationId
      }
      if (roleCodes.includes(RoleCodes.MANAGER)) {
        query.managerId = this.managerId
      }
      if (roleCodes.includes(RoleCodes.INVITER)) {
        query.inviterId = this.inviterId
      }
      if (roleCodes.includes(RoleCodes.TRAINER)) {
        query.trainerId = this.trainerId
      }
      if (roleCodes.includes(RoleCodes.ADMIN)) {
        query.adminId = this.adminId
      }
      this.$router.push({
        name: this.links[linkIndex].subLinks[subLinkIndex].name,
        route: route,
        query,
        params: {}
      })
    },
    logout () {
      this.$store.state.isUserLoggedIn = false
      this.$store.dispatch('setToken', null)
      this.$store.dispatch('setAccount', null)
      this.$store.dispatch('setVersion', null)
      this.$store.dispatch('setQueryingState', null)
      this.$store.dispatch('setRoleCodes', null)
      this.$store.dispatch('setUser', null)
      // redirect to login page ..
      this.$router.push({
        name: 'login'
      })
    }
  }
}
</script>
<!--
首页 // 信息汇总显示
管理
  + 项目(ballot) // D查看、D增加、?编辑项目(包括隐藏删除等)，?每个项目下拉出具体项目购买选项，并可以编辑。
  + 班级 // 上方选择项目，根据项目对班级进行：D查看、增加、编辑(更换承接方)\增加变换等学员管理操作。
  + 学员 // 上方选择项目，根据项目对学员进行：查看、修改状态、班级状态、课时状态汇总
  + 教师(person) // 查看本机构教师
  + 报名情况 // 上方选择项目，列出本项目报名学生
授课
  + 模版列表 // 新增模版
  + 排课 // 安排现有班级上课，学生完成Enroll
  + 签到 // 每模版签到人数及状态修改签到状态
收入
  + 查看收入历史记录情况
设置
  + 机构简绍
  + 机构负责教师
ICON使用
项目：ballot
时间：schedule
教师：person
学员：group
class: weekend
host: person_outline
venue: store
 -->
