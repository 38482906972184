import Api from '@/services/Api' // axois object created in api.js

export default { // export its register method
  getProgram ({ tutorId = null, organizationId = null, statusCodes = null }) {
    return Api().get('program/', { params: { 'tutorId': tutorId, 'organizationId': organizationId, 'statusCodes': statusCodes } })
  },
  getClasses ({ programIds, statusCodes, instructorTutorIds, hostIds, organizationIds, classIds, attachTutors, findOne }) {
    return Api().get('program/classes', { params: { programIds, statusCodes, instructorTutorIds, hostIds, organizationIds, classIds, attachTutors, findOne } })
  },
  getPrograms ({ managerIds, organizationIds, programIds, tutorId, organizationId, statusCodes, attatchPaidedStudentsInfo, attatchTags, attatchPricePackages, attatchMedia, attatchDetailedMedias }) {
    return Api().get('program', { params: { managerIds, tutorId, organizationId, programIds, organizationIds, statusCodes, attatchPaidedStudentsInfo, attatchTags, attatchPricePackages, attatchMedia, attatchDetailedMedias } })
  },
  getCategorizedPrograms ({ tutorId, organizationId, programIds, isPublished, isDraft, isPending, isSnapshot, isClosed, visibleToPublic, attatchPaidedStudentsInfo, attatchTags, attatchPricePackages, attatchMedia, attatchDetailedMedias }) {
    console.log('ProgramService.getCategorizedPrograms starts ... ')
    return Api().get('program/get-programs', { params: { tutorId, organizationId, programIds, isPublished, isDraft, isPending, isSnapshot, isClosed, visibleToPublic, attatchPaidedStudentsInfo, attatchTags, attatchPricePackages, attatchMedia, attatchDetailedMedias } })
  },
  getProgramsFromCommonProperty ({ programCommonPropertyIds, programCommonPropertyStatusCodes, tutorIds, organizationIds, programIds, organizationGroupIds, first, attatchPaidedStudentsInfo, attatchTags, attatchPricePackages, attatchMedia, attatchDetailedMedias }) {
    return Api().get('program/get-programs-from-common-property', { params: { programCommonPropertyIds, programCommonPropertyStatusCodes, tutorIds, organizationIds, programIds, organizationGroupIds, first, attatchPaidedStudentsInfo, attatchTags, attatchPricePackages, attatchMedia, attatchDetailedMedias } })
  },
  getStudents ({ programIds, organizationIds, studentIds, enrollmentStatusCodes, classIds, tutorIds, inviterIds, attachProgramInfo, offset, limit }) {
    console.log('ProgramService.getStudents starts ... ')
    console.log(`inviterIds: ${inviterIds}`)
    return Api().get('program/students/', { params: { programIds, organizationIds, studentIds, enrollmentStatusCodes, classIds, tutorIds, inviterIds: inviterIds, attachProgramInfo, offset, limit } })
  },
  getOrganizationTransactionRecordsByOrganizationId (organizationId, limit, offset) {
    console.log('Sending request via GET method to server, waiting for response ...')
    return Api().get('finance/org-transaction-records', { params: { organizationId: organizationId, limit: limit, offset: offset } })
  },
  getHostMoneyRecordsById ({ hostId, limit, offset }) {
    console.log(`getHostMoneyRecordsById, hostId: ${hostId}. /nSending request via GET method to server, waiting for response ...`)
    return Api().get('finance/host-transaction-records/', { params: { host_id: hostId, limit: limit, offset: offset } })
  },
  studentClassDisenrollment (studentId, classId) {
    console.log('\n studentClassDisenrollment: Sending request via POST method to server, waiting for response ...')
    return Api().delete('program/class-enrollment', { params: { student_id: studentId, class_id: classId } })
  },
  studentClassEnroll (studentIds, classId) {
    console.log('#\nstudentClassEnroll: sending request via POST method to server, waiting for response ...')
    return Api().post('program/class-enrollment', { studentIds: studentIds, classId: classId })
  },
  getClassStatusItems () {
    return Api().get('program/class-status-items')
  },
  updateClassStatus (classId, statusCode) {
    console.log(`updateClassStatus: classId: ${classId}, statusCode: ${statusCode}`)
    return Api().put('program/class-update', { id: classId, status_code: statusCode })
  },
  getStudentLessonRecords (studentId, programId, limit, offset) {
    console.log('Sending request via GET method to server, waiting for response ...')
    return Api().get('finance/student-lesson-records', { params: { student_id: studentId, program_id: programId, limit: limit, offset: offset } })
  },
  upsertClass (classInfo) {
    console.log('ProgramService.upsertClass starts ... ')
    return Api().put('program/class-upsert', classInfo)
  },
  deleteClass (classId) {
    console.log(`delete class, id: ${classId}`)
    return Api().delete('program/class', { params: { class_id: classId } })
  },
  getTutors ({ organizationIds, statusCodes, tutorId, mobile, organizationId }) {
    console.log('Sending request via GET method to server, waiting for server response ...')
    return Api().get('program/tutors', { params: { statusCodes, organizationIds, tutor_id: tutorId, mobile, organization_id: organizationId } })
  },
  getProgramCategory3 () {
    console.log('Sending request via GET method to server, waiting for server response ...')
    return Api().get('program-category-3', { params: { codes: null } })
  },
  getProgramCategory2 (category3Codes) {
    console.log('Sending request via GET method to server, waiting for server response ...')
    return Api().get('program-category-2', { params: { category3Codes: category3Codes } })
  },
  getProgramCategory (category2Codes) {
    console.log('Sending request via GET method to server, waiting for server response ...')
    return Api().get('program-category', { params: { category2Codes: category2Codes } })
  },
  getProgramPrerequisiteTags () {
    console.log('Sending request via GET method to server, waiting for server response ...')
    return Api().get('program/prerequisite-tags', { params: {} })
  },
  getProgramTags () {
    console.log('Sending request via GET method to server, waiting for server response ...')
    return Api().get('program/program-tags', { params: {} })
  },
  getProgramTargetStudentTags () {
    console.log('Sending request via GET method to server, waiting for server response ...')
    return Api().get('program/target-student-tags', { params: {} })
  },
  getProgramObjectiveTags () {
    console.log('Sending request via GET method to server, waiting for server response ...')
    return Api().get('program/objective-tags', { params: {} })
  },
  upsertProgram (programInfo, excludeMedias = false) {
    console.log('Sending request to server ...')
    if (excludeMedias && programInfo.medias) {
      delete programInfo.medias
      console.log('忽略programInfo.medias的更新.')
    }
    return Api().post('program/program-upsert', { programInfo })
  },
  deletePrograms ({ programCommonPropertyIds }) {
    console.log(' ProgramService.deletePrograms...')
    return Api().delete('program/delete-programs', { params: { programCommonPropertyIds } })
  },
  approvalRequest ({ programCommonPropertyId, programId }) {
    console.log('ProgramService.approvalRequest... ')
    return Api().post('program/program-approval-request', { programCommonPropertyId, programId })
  },
  approvalRequestWithdraw ({ programApprovalRequestId }) {
    console.log('ProgramService.approvalRequestWithdraw... ')
    return Api().post('program/program-approval-request-withdraw', { programApprovalRequestId })
  },
  attachThumbnail ({ programCommonPropertyId, fileName }) {
    console.log('ProgramService.attachThumbnail... ')
    return Api().post('program/program-attach-thumbnail', { programCommonPropertyId, fileName })
  },
  getProgramPackageForPurchaseCategories () {
    console.log('ProgramService.getProgramPackageForPurchaseCategories... ')
    return Api().get('program/get-program-package-for-purchase-categories')
  },
  programPackageForPurchaseUpsert ({ programPackageForPurchase }) {
    console.log('ProgramService.programPackageForPurchaseUpsert... ')
    return Api().post('program/program-package-for-purchase-upsert', { programPackageForPurchase })
  },
  deleteProgramPackagesForPurchase ({ programPackagesForPurchaseIds }) {
    console.log(' ProgramService.deleteProgramPackagesForPurchase...')
    return Api().delete('program/program-packages-for-purchase-delete', { params: { programPackagesForPurchaseIds } })
  },
  getSkillLevelsDefinition ({ levelCodes, categoryCodes, programCategory2Codes, fetchGallery }) {
    console.log('ProgramService.getProgramSkillLevelsDefinition... ')
    return Api().get('program/levels-definition', { params: { levelCodes, categoryCodes, programCategory2Codes, fetchGallery } })
  },
  programMediaUpsert ({ programMediaId, fileName, text, categoryCode, programCommonPropertyId, afterMediaId }) {
    console.log('ProgramService.programMediaUpsert... ')
    return Api().post('program/program-media-upsert', { programMediaId, fileName, text, categoryCode, programCommonPropertyId, afterMediaId })
  },
  programMediasDelete ({ programMediaIds, programCommonPropertyIds }) {
    console.log('ProgramService.programMediasDelete... ')
    return Api().delete('program/program-medias-delete', { params: { programMediaIds, programCommonPropertyIds } })
  },
  programMediaSwap ({ programMedia1Id, programMedia2Id }) {
    console.log('ProgramService.programMediaSwap... ')
    return Api().put('program/program-media-swap', { programMedia1Id, programMedia2Id })
  },
  venueAssignment ({ classId, venueId }) {
    return Api().put('program/venue-assignment-upsert', { classId, venueId })
  },
  getVenues ({ hostIds, addressIds, classIds, venueIds, venueStatusCodes }) {
    console.log('Sending request via GET method to server, waiting for response ...')
    return Api().get('program/get-venues', { params: { hostIds, addressIds, classIds, venueIds, venueStatusCodes } })
  }
  // createProgram (Info) {
  //   return Api().post('createProgram', Info)
  // },
  // venueAssignmentRemoval (classId, venueId) {
  //   return Api().post('venueAssignmentRemoval', { classId: classId, venueId: venueId })
  // },
  // getLessonSessionVenueStatusItems () {
  //   return Api().get('getLessonSessionVenueStatusItems')
  // },
  // getLessonSessionTutorStatusItems () {
  //   return Api().get('getLessonSessionTutorStatusItems')
  // },
  // getProgramPackageInfoByProgramId (programId) {
  //   return Api().get('getProgramPackageInfoByProgramId', { params: { programId: programId } })
  // },
  // updateProgram (programInfo, programId = null) {
  //   return Api().post('createProgram', { programInfo: programInfo, programId: programId })
  // },
  // getStudentsByClassId (classId) {
  //   return Api().get('getStudentsByClassId', { params: { classId: classId } })
  // },
  // getStudentById (studentId, programId = null) {
  //   return Api().get('getStudentById', { params: { studentId: studentId, programId: programId } })
  // },
  // getStudentsByOrganizationId (organizationId) {
  //   console.log('Sending request via GET method to server, waiting for server response ...')
  //   return Api().get('getStudentsByOrganizationId', { params: { organizationId: organizationId } })
  // },
  // programPackagePurchase (packageInfo, studentId) {
  //   console.log('Sending request via POST method to server, waiting for response ...')
  //   return Api().post('programPackagePurchase', { packageInfo: packageInfo, studentId: studentId })
  // },
  // getUnenrolledStudentsByProgramId (programId) {
  //   console.log('Sending request via GET method to server, waiting for response ...')
  //   return Api().get('getUnenrolledStudentsByProgramId', { params: { programId: programId } })
  // },
}
